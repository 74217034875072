// FHIR
import { StatusTag, Title } from "@fyrstain/fhir-front-library";
// React
import {
  FunctionComponent
} from "react";
//components
import { Card } from "react-bootstrap";
// Services
import { Document } from "../../services/DocumentService";
// Styles
import styles from "./BundleDocumentViewer.module.css";
// QR Code
import QRCode from "react-qr-code";

const BundleDocumentViewer: FunctionComponent<{ document: Document }> = (props) => {

  const document = props.document;

  /////////////////////////////////////
  //             Content             //
  /////////////////////////////////////

  return (
    <>
      <Card>
        <Card.Header>
          <Title
            level={2}
            prefix={"Type de document :"}
            content={document?.type} />
          <Title
            level={2}
            prefix={"Sujet du document :"}
            content={document?.subject} />
        </Card.Header>
        {document?.sections?.map((section) => (
          <Card className={styles.containerSection}>
            <Card.Header className={styles.headerCard}>
              <Title level={3} prefix={section?.display} />
            </Card.Header>
            <div className={styles.container}>
              <div className={styles.containerflex}>
                {section.resources.map((resource) => (
                  <Card className={styles.cardColor}>
                    {{
                      MedicationStatement: (
                        <Card.Body>
                          <Card.Text>
                            {" "}
                            <div className={styles.statusTag}>
                              <b>Status :</b>
                              {resource.resource.status === "active" ||
                                resource.resource.status === "completed" ? (
                                <StatusTag
                                  status={"error"}
                                  statusMessage={resource.resource.status} />
                              ) : (
                                <StatusTag
                                  status={"success"}
                                  statusMessage={resource.resource.status ?? "Inconnu"} />
                              )}
                            </div>
                          </Card.Text>
                          <Card.Text className={styles.medicationLineBreak}>
                            <b>Medication : </b>
                            {resource.resource.medicationReference?.reference}
                          </Card.Text>
                          <br></br>
                        </Card.Body>
                      ),
                      Condition: (
                        <Card.Body>
                          <Card.Text>
                            <div className={styles.statusTag}>
                              <b>Clinical status :</b>{" "}
                              {resource.resource.clinicalStatus?.coding[0]
                                .code === "active" ||
                                resource.resource.clinicalStatus?.coding[0]
                                  .code === "recurrence" ||
                                resource.resource.clinicalStatus?.coding[0]
                                  .code === "relapse" ? (
                                <StatusTag
                                  status={"error"}
                                  statusMessage={resource.resource.clinicalStatus
                                    ?.coding[0].code} />
                              ) : (
                                <StatusTag
                                  status={"success"}
                                  statusMessage={resource.resource.clinicalStatus
                                    ?.coding[0].code ?? "Inconnu"} />
                              )}
                            </div>
                          </Card.Text>
                          <Card.Text className={styles.labelText}>
                            <b>Pathology : </b>
                            {resource.resource.code?.coding[0].display ?? resource.resource.code?.coding[0].code}
                          </Card.Text>
                          <br></br>
                        </Card.Body>
                      ),
                      Procedure: (
                        <Card.Body>
                          <Card.Text><b>Type : </b>
                            {resource.resource.code?.coding[0].display ?? resource.resource.code?.coding[0].code}</Card.Text>
                          <br></br>
                        </Card.Body>
                      ),
                      Observation: (
                        <Card.Body>
                          <Card.Text><b>Type : </b>
                            {resource.resource.code?.coding[0].display ?? resource.resource.code?.coding[0].code}</Card.Text>
                          <br></br>
                        </Card.Body>
                      ),
                      DiagnosticReport: (
                        <Card.Body>
                          <Card.Text>{resource.type}</Card.Text>
                          <br></br>
                        </Card.Body>
                      ),
                      Specimen: (
                        <Card.Body>
                          <Card.Text>{resource.type}</Card.Text>
                          <br></br>
                        </Card.Body>
                      ),
                      Immunization: (
                        <Card.Body>
                          <Card.Text>
                            <div className={styles.statusTag}>
                              <b>Status :</b>
                              {resource.resource.status === "completed" ? (
                                <StatusTag
                                  status={"error"}
                                  statusMessage={resource.resource.status} />
                              ) : (
                                <StatusTag
                                  status={"success"}
                                  statusMessage={resource.resource.status ?? "Inconnu"} />
                              )}
                            </div>
                          </Card.Text>
                          <label className={styles.labelText}>
                            <b>Type :</b>{" "}
                            {resource.resource.vaccineCode?.coding[0].display ?? resource.resource.vaccineCode?.coding[0].code}
                          </label>
                          <br></br>
                        </Card.Body>
                      ),
                      AllergyIntolerance: (
                        <Card.Body>
                          <Card.Text className={styles.labelText}>
                            <b>Type : </b>
                            {resource.resource.code?.coding[0].code}-
                            {resource.resource.code?.coding[0].display}
                          </Card.Text>
                          <br></br>
                        </Card.Body>
                      ),
                    }[String(resource.type)]}
                  </Card>
                ))}
              </div>
            </div>
          </Card>
        ))}
      </Card>
      <div>
        <QRCode value={document.bundleUrl ?? ''} />
      </div>
    </>
  );
};

export default BundleDocumentViewer;
