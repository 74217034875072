// FHIR
import Client from "fhir-kit-client";
import { Bundle } from "fhir/r4";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { QrReader } from "react-qr-reader";
// Navigation
import { useNavigate, useParams } from "react-router-dom";
// Components
import PapyrusPage from "../../components/PapyrusPage/PapyrusPage";
import BundleDocumentViewer from "../../components/BundleViewer/BundleDocumentViewer";
//Services
import DocumentService, { Document } from "../../services/DocumentService"
import CDSService, { Card } from "../../services/CDSService";

const QRCodeScanner: FunctionComponent = () => {

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////
    const { documentId } = useParams();
    const [data, setData] = useState<unknown | Document>(null);
    const [dataBundle, setDataBundle] = useState<unknown | Bundle>(null);
    const [subject, setSubject] = useState<string>("");
    const [cards, setCards] = useState<Map<string, Card>>(new Map<string, Card>());
    const [qrCodeContent, setQrCodeContent] = useState("");
    const [isOpen, setIsOpen] = useState<boolean[]>([]);

    /////////////////////////////////////
    //             Client              //
    /////////////////////////////////////

    const fhirClient = useMemo(() => new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    }), []);

    /////////////////////////////////////
    //           Navigation            //
    /////////////////////////////////////

    const navigate = useNavigate();

    /////////////////////////////////////
    //             Errors              //
    /////////////////////////////////////

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /////////////////////////////////////
    //          Page Loading           //
    /////////////////////////////////////

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadPage();
    }, []);

    useEffect(() => {
        loadPage();
    }, [i18n.language]);

    /**
 * Load the initial state of the page.
 */
    async function loadPage() {
        setLoading(true);
        if (documentId) {
            await loadDocument()
        }
        setLoading(false);
    }

    /////////////////////////////////////
    //             Content             //
    /////////////////////////////////////

    /**
     *  Get QR code information 
     *  @param text the recovered text
     */
    function getQRInfo(text: string) {
        if (text) {
            fetch(text, { mode: 'no-cors', method: 'GET', headers: { 'Access-Control-Allow-Origin': '*' } }).then((response) => {
                response.json().then((bundle) => {
                    setData(DocumentService.generateDoc(bundle));
                    setDataBundle(bundle);
                    setSubject(DocumentService.getComposition(bundle)?.subject?.reference ?? "")

                })
            });

        }
    }

    /**
   * Load patient from the back to populate the fields.
   *
   * @returns the promise of a Patient.
   */
    async function loadDocument() {
        try {
            let bundle: Bundle;
            if(!dataBundle) {
                const response = await fhirClient.read({
                    resourceType: "Bundle",
                    id: documentId ?? "",
                });
                bundle = response as Bundle;
                setDataBundle(bundle);
            } else {
                bundle = dataBundle as Bundle;
            }

            DocumentService.generateDoc(bundle).then((document) => {
                setData(document);
                setDataBundle(bundle);
                setSubject(DocumentService.getComposition(bundle.entry ?? [])?.subject?.reference ?? "");
                let qrcodecontent = fhirClient.baseUrl + '/Bundle/' + documentId;
                setQrCodeContent(qrcodecontent);
            });
        } catch (error) {
            onError();
        }
    }

    /**
        *  Get QR code information 
        *  @param i specific map index
        */
    function toggle(i: number) {
        setIsOpen({ ...isOpen, [i]: !isOpen[i] });
    }

    /**
     *  Display the toasts after clicking on the button
     */
    async function handleClick() {
        const newCards = await CDSService.callCDS("", subject, dataBundle as Bundle);
        setCards(newCards);
        const newIsOpen = Array.from(newCards.keys()).map((cardId, i) => true);
        setIsOpen(newIsOpen);
    }


    return (
        <>
            <ToastContainer position='top-end'>
                {Array.from(cards.keys()).map((cardId, i) => (
                    <Toast show={isOpen[i]} onClose={() => toggle(i)}>
                        <Toast.Header>
                            <strong className="me-auto">{cards.get(cardId)?.indicator}</strong>
                        </Toast.Header>
                        <Toast.Body><strong className="me-auto">{cards.get(cardId)?.summary}</strong>
                            <br></br>
                            <small className="text-muted">{cards.get(cardId)?.source}</small>
                        </Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
            <PapyrusPage titleKey='title.bundleDocument' loading={loading} fitFooter={false} needsLogin={false}>
                <>
                    {(!data && !documentId) && (<QrReader
                        onResult={(result, error) => {
                            if (!!result) {
                                getQRInfo(result?.getText());
                            }

                            if (!!error) {
                                console.info(error);
                            }
                        }}
                        constraints={{}}
                        containerStyle={{ width: '50%' }} />)}
                    {((data as Document) && (dataBundle as Bundle)) && (<>
                        <Button variant="primary" onClick={handleClick}>
                            {i18n.t("button.chekingpatientrisk")}
                        </Button>
                        <BundleDocumentViewer document={data as Document}></BundleDocumentViewer>
                    </>
                    )}
                </>
            </PapyrusPage></>
    );
};

export default QRCodeScanner;
