// Components
import BundleDocumentViewer from "../../components/BundleViewer/BundleDocumentViewer";
import PapyrusPage from "../../components/PapyrusPage/PapyrusPage";
// Client 
import Client from "fhir-kit-client";
// FHIR
import { Bundle } from "fhir/r4";
// Translation
import i18n from "i18next";
// React
import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
// React Bootstrap
import { Button, Toast, ToastContainer } from "react-bootstrap";
// QR Code
import { QrReader } from "react-qr-reader";
// Router
import { useNavigate, useParams } from "react-router-dom";
// Services
import CDSService, { Card } from "../../services/CDSService";
import DocumentService, { Document } from "../../services/DocumentService";

const QRCodeScanner: FunctionComponent = () => {

    /////////////////////////////////////
    //             State               //
    /////////////////////////////////////

    const { documentId } = useParams();
    const [data, setData] = useState<unknown | Document>(null);
    const [dataBundle, setDataBundle] = useState<unknown | Bundle>(null);
    const [subject, setSubject] = useState<string>("");
    const [cards, setCards] = useState<Map<string, Card>>(new Map<string, Card>());
    const [isOpen, setIsOpen] = useState<boolean[]>([]);
    const [loading, setLoading] = useState(false);
    const [qrCodeContent, setQrCodeContent] = useState<string | null>(null);

    /////////////////////////////////////
    //             Client              //
    ////////////////////////////////////

    const fhirClient = useMemo(() => new Client({
        baseUrl: process.env.REACT_APP_FHIR_URL ?? 'fhir'
    }), []);

    /////////////////////////////////////
    //           Navigation            //
    /////////////////////////////////////

    const navigate = useNavigate();

    const onError = useCallback(() => {
        navigate("/Error");
    }, [navigate]);

    /////////////////////////////////////
    //          Page Loading           //
    /////////////////////////////////////

    /**
     * Load the page
     * 
     */
    useEffect(() => {
        loadPage();
    }, []);

    /**
    * Load the initial state of the page.
    */
    async function loadPage() {
        setLoading(true);
        if (documentId) {
            await loadDocument()
        }
        setLoading(false);
    };

    /////////////////////////////////////
    //             Functions           //
    /////////////////////////////////////

    /**
     * Get the QR code information 
     * 
     * @param text 
     * 
     */
    async function getQRInfo(text: string) {
        if (text) {
            try {
                const response = await fetch(text, { mode: 'no-cors', method: 'GET', headers: { 'Access-Control-Allow-Origin': '*' } });
                const bundle = await response.json();
                const document = await DocumentService.generateDoc(bundle);
                setData(document);
                setDataBundle(bundle);
                setSubject(DocumentService.getComposition(bundle.entry ?? [])?.subject?.reference ?? "");
            } catch (error) {
                onError();
            }
        }
    };

    /**
     * Load the document
     * 
     */
    async function loadDocument() {
        try {
            let bundle: Bundle;
            if (!dataBundle) {
                const response = await fhirClient.read({
                    resourceType: "Bundle",
                    id: documentId ?? "",
                });
                bundle = response as Bundle;
                setDataBundle(bundle);
            } else {
                bundle = dataBundle as Bundle;
            }
            const document = await DocumentService.generateDoc(bundle);
            setData(document);
            setSubject(DocumentService.getComposition(bundle.entry ?? [])?.subject?.reference ?? "");
        } catch (error) {
            onError();
        }
    };

    /**
     * Get QR code information 
     * 
     * @param i 
     */
    function toggle(i: number) {
        setIsOpen({ ...isOpen, [i]: !isOpen[i] });
    };

    /**
     * Handle the click
     * 
     */
    async function handleClick() {
        const newCards = await CDSService.callCDS("", subject, dataBundle as Bundle);
        setCards(newCards);
        const newIsOpen = Array.from(newCards.keys()).map(() => true);
        setIsOpen(newIsOpen);
    };

    /////////////////////////////////////
    //              Content            //
    /////////////////////////////////////

    return (
        <>
            <ToastContainer position='top-end'>
                {Array.from(cards.keys()).map((cardId, i) => (
                    <Toast show={isOpen[i]} onClose={() => toggle(i)}>
                        <Toast.Header>
                            <strong className="me-auto">{cards.get(cardId)?.indicator}</strong>
                        </Toast.Header>
                        <Toast.Body><strong className="me-auto">{cards.get(cardId)?.summary}</strong>
                            <br></br>
                            <small className="text-muted">{cards.get(cardId)?.source}</small>
                        </Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
            <PapyrusPage titleKey='title.bundleDocument' loading={loading} fitFooter={false} needsLogin={false}>
                <>
                    {(!data && !documentId) && (<QrReader
                        onResult={(result, error) => {
                            if (!!result) {
                                getQRInfo(result?.getText());
                            }
                        }}
                        constraints={{}}
                        containerStyle={{ width: '50%' }} />)}
                    {((data as Document) && (dataBundle as Bundle)) && (<>
                        <Button variant="primary" onClick={handleClick}>
                            {i18n.t("button.chekingpatientrisk")}
                        </Button>
                        <BundleDocumentViewer document={data as Document}></BundleDocumentViewer>
                    </>
                    )}
                </>
            </PapyrusPage>
        </>
    );
};

export default QRCodeScanner;