import { FunctionComponent } from "react";
import PapyrusPage from "../../components/PapyrusPage/PapyrusPage";
import styles from "./Error.module.css";
import { Title } from "@fyrstain/fhir-front-library";
import i18n from "i18next";
 
const Error: FunctionComponent = () => {
 
    return <PapyrusPage loading={false} fitFooter={true} needsLogin={false} >
        <div className={styles.mainErrorContainer}>
            <img
                className={styles.imageIcon}
                src="../assets/oops.png"
                alt="Oops !"
            />
            <Title level={1} prefix={i18n.t('title.error.oops')} content={i18n.t('title.error.problem')} />
        </div>
    </PapyrusPage>
};
 
export default Error;
