// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Error_imageIcon__ottRM {
    width: 25%;
    height: auto;
    margin-right: 4rem;
}
 
.Error_mainErrorContainer__z6\\+SN {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10rem;
    height: 90%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Error/Error.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,WAAW;AACf","sourcesContent":[".imageIcon {\r\n    width: 25%;\r\n    height: auto;\r\n    margin-right: 4rem;\r\n}\r\n \r\n.mainErrorContainer {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    margin-top: 10rem;\r\n    height: 90%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageIcon": `Error_imageIcon__ottRM`,
	"mainErrorContainer": `Error_mainErrorContainer__z6+SN`
};
export default ___CSS_LOADER_EXPORT___;
