// React
import { FunctionComponent } from "react";
// Library
import { Title } from "@fyrstain/fhir-front-library";
// Components
import PapyrusPage from "../../components/PapyrusPage/PapyrusPage";
// Styles
import styles from "./Home.module.css";

const Home: FunctionComponent = () => {
    return (
        <PapyrusPage loading={false} fitFooter={true} needsLogin={false}>
            <>
                <div className={styles.mainHomeContainer}>
                    <img
                        className={styles.homeimageIcon}
                        alt="Home_image"
                        src="/assets/home_diagnostic_reports.png"
                    />
                    <Title level={1} prefix={'Papyrus'} />
                </div>
            </>
        </PapyrusPage>
    );
};

export default Home;
