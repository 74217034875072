import { Parameters, Bundle } from "fhir/r4";
import Client from "fhir-kit-client";

export  interface Card {
    summary: string;
    indicator: ('info'|'warning'|'critical');
    source: string;
}

const fhirClient = new Client({
  baseUrl: process.env.REACT_APP_FHIR_URL ?? "fhir",
});

async function callCDS(service: string, patient: string, data: Bundle): Promise<Map<string, Card>> {
    var patientId = (new String(patient)).split('/').toReversed()[0];
    var cards = new Map<string,Card>();
    // cards.set("id1",{summary : "my First Card", indicator : "warning", source : "my source"})
    var card = await calculateRisk("ImmunoAtRisk", patientId, data);
    if(card) {
      cards.set("ImmunoAtRisk", card);
    }
    // cards.set("id2",{summary : "my Second Card", indicator : "info", source : "my source"})
    // cards.set("id3",{summary : "my Third Card", indicator : "critical", source : "my source"})
    return cards;
}

function calculateRisk(lib :string, patient: string, data: Bundle): Promise<Card | null> {
  var card: Promise<Card | null>;
  const parameters: Parameters = {
    resourceType: "Parameters",
    parameter: [
      // {
      //   name: "content",
      //   valueString: sourceCode,
      // },
      {
        name: "terminologyEndpoint",
        resource: {
          resourceType: "Endpoint",
          status: "active",
          connectionType: {
            system:
              "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
            code: "hl7-fhir-rest",
          },
          payloadType: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
                  code: "hl7-fhir-rest",
                  display: "HL7 FHIR",
                },
              ],
            },
          ],
          address: process.env.REACT_APP_FHIR_URL ?? "",
          header: ["Content-Type: application/json"],
        },
      },
      {
        name: "contentEndpoint",
        resource: {
          resourceType: "Endpoint",
          status: "active",
          connectionType: {
            system:
              "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
            code: "hl7-fhir-rest",
          },
          payloadType: [
            {
              coding: [
                {
                  system:
                    "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
                  code: "hl7-fhir-rest",
                  display: "HL7 FHIR",
                },
              ],
            },
          ],
          address: process.env.REACT_APP_FHIR_URL ?? "",
          header: ["Content-Type: application/json"],
        },
      },
      {
        name: "subject",
        valueString: patient,
      },
      {
        name: "data",
        resource: data,
      }
    ],
  };


  card = fhirClient
    .operation({
      resourceType: "Library",
      name: "$evaluate",
      id: lib,
      method: "POST",
      input: parameters,
    })
    .then((response) => {
      var atRisk = (response as Parameters).parameter?.filter(
        (param) => param.name == "atRisk"
      )[0].valueBoolean ?? false;
      if (atRisk) {
      return {summary : "Immunodepressed + EBV", indicator : "warning", source : "my library"};}
      else return null});
  return card;
}


const CDSService = {
    callCDS,
  };
  
  export default CDSService;