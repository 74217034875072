//FHIR Client and model
import Client from "fhir-kit-client";
import { Parameters, Coding } from "fhir/r4";

////////////////////////////
//        Class           //
////////////////////////////

export class TerminologyService {

    fhirClient: Client;

    /**
     * Constructor for the class. If no Client is provided, a new client is created 
     * by default using the REACT_APP_TERMINOLOGY_URL environment variable.
     *
     * @param fhirClient the client used to call the terminology service.
     */
    constructor(fhirClient?: Client) {
        if (fhirClient) {
            this.fhirClient = fhirClient;
        } else {
            this.fhirClient = new Client({
                baseUrl: process.env.REACT_APP_TERMINOLOGY_URL ?? "fhir",
            })
        }
    }

    /**
     * Translates a coding using the $^lookup operation.
     * 
     * @param coding the coding to translate
     * @param systemVersion the target version of the system
     * @param displayLanguage the language to translate to
     * @returns the Promise of the translated Coding.
     */
    async translateDisplay(coding: Coding, systemVersion: string, displayLanguage: string): Promise<Coding> {
        const parameters: Parameters = {
            resourceType: "Parameters",
            parameter: [
                {
                    name: "system",
                    valueUri: coding.system,
                },
                {
                    name: "version",
                    valueString: systemVersion
                },
                {
                    name: "code",
                    valueCode: coding.code
                },
                {
                    name: "displayLanguage",
                    valueCode: displayLanguage
                }
            ],
        };

        return this.fhirClient.operation({
            resourceType: "CodeSystem",
            name: "$lookup",
            method: "POST",
            input: parameters,
        }).then(response => {
            if (response.resourceType !== 'Parameters') {
                throw Error(response.statusText);
            }
            const parameters: Parameters = response as Parameters;
            const system = parameters.parameter?.filter(parameter => parameter.name === "system").map(parameter => parameter.valueUri).at(0);
            const code = parameters.parameter?.filter(parameter => parameter.name === "code").map(parameter => parameter.valueCode).at(0);
            const display = parameters.parameter?.filter(parameter => parameter.name === "display").map(parameter => parameter.valueString).at(0);
            return {
                system: system,
                code: code,
                display: display,
            } as Coding;
        }).catch (e => {
            console.log("Cannot translate code : " + JSON.stringify(coding), e);
            return coding;
        });
    }
}

export default TerminologyService;